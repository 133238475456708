import {customElement, property, state} from 'lit/decorators.js';
import {sbkMetaNavStyle} from './meta-nav.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-meta-nav')
export class SbkMetaNav extends LitElement {

    @property({attribute: 'logo-path'})
    logoPath = ''

    @property({attribute: 'logo-alt-text'})
    logoAltText = ''

    @property({attribute: 'logo-link'})
    logoLink = ''

    @property()
    variant: 'default' | 'reduced' = 'default'

    @state()
    private _hasMetaNavItems = false;


    static get styles() {
        return sbkMetaNavStyle;
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('keydown', this._handleKeyDown);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    private _handleKeyDown = (event: KeyboardEvent) => {
        const focusableElements = Array.from(this.querySelectorAll('sbk-tab-menu-item')) as HTMLElement[];

        if (!focusableElements.length) return;

        const activeElement = document.activeElement as HTMLElement;
        const currentIndex = focusableElements.indexOf(activeElement);
        if (currentIndex === -1) return;

        let newIndex;
        if (event.key === 'ArrowRight') {
            newIndex = (currentIndex + 1) % focusableElements.length;
        } else if (event.key === 'ArrowLeft') {
            newIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length;
        } else {
            return;
        }

        event.preventDefault();
        const focusElement = focusableElements[newIndex].shadowRoot?.firstElementChild?.firstElementChild as HTMLElement;
        focusElement?.focus();
    };



    render() {
        if (this.variant === 'reduced') {
            return html`
                <div class="meta-nav meta-nav-reduced">
                    <div class="meta-nav__wrapper">
                        ${this.renderLogoLink()}
                        <div class="meta-nav__wrapper-items">
                            <div class="meta-nav-items">
                                <slot></slot>
                            </div>
                        </div>
                        <div class="meta-nav-actions">
                            <div class="meta-nav-icons ${this._hasMetaNavItems ? '' : 'standAlone'}">
                                <slot name="icons"></slot>
                            </div>
                            <div class="meta-nav-cta">
                                <slot name="cta"></slot>
                            </div>
                        </div>

                    </div>
                </div>
            `;
        }
        return html`
            <div class="meta-nav">
                <div class="meta-nav__wrapper">
                    ${this.renderLogoLink()}
                    <div class="meta-nav__wrapper-items">
                        <div class="meta-nav-items">
                            <slot></slot>
                        </div>
                        <div class="meta-nav-icons">
                            <span class="verticalDivider"></span>
                            <slot name="icons"></slot>
                        </div>
                    </div>
                    <div class="meta-nav-cta">
                        <slot name="cta"></slot>
                    </div>
                </div>
            </div>
        `;
    }

    renderLogoLink() {
        if (!this.logoLink) {
            return this.renderLogo();
        }
        return html`
            <a href=${this.logoLink}>
                ${this.renderLogo()}
            </a>
        `;
    }

    renderLogo() {
        if (!this.logoPath) {
            return '';
        }
        return html`
            <img src=${this.logoPath} alt=${this.logoAltText} class="logo"/>
        `;
    }

    firstUpdated() {
        const slot: HTMLSlotElement | null | undefined = this.shadowRoot?.querySelector('slot');
        if (slot) {
            this._hasMetaNavItems = slot?.assignedElements({flatten: true}).length > 0;
        }
    }
}
